import {storage, toggleButtonLoading, showJoinKidsAcademyModal} from './utils';
import {ENDPOINT, LOCAL_STORAGE_KEYS, MAILING_LISTS} from './config';
import toastr from 'toastr';

const $document = $(document);

$document.ready(function () {
  const $kaComStaticPage = $('[data-selector="kidsacademy-com-static"]');
  if (!$kaComStaticPage.length) return;

  const showPopupBtn = '[data-selector="show-popup"]';
  const $tgcSubscriptionPopup = $('[data-selector="tgc-subscription-popup"]');
  const $tgcSubscriptionPopupSubscribe = $('[data-selector="tgc-subscription-popup-subscribe"]');
  const $tgcSubscriptionPopupSuccess = $('[data-selector="tgc-subscription-popup-success"]');
  const tgcSubscriptionPopupSubmit = '[data-selector="tgc-subscription-popup-submit"]';
  const $tgcSubscriptionPopupSubmit = $(tgcSubscriptionPopupSubmit);
  const $tgcSubscriptionPopupForm = $('[data-selector="tgc-subscription-popup-form"]');
  const $tgcSubscriptionPopupInput = $('[data-selector="tgc-subscription-popup-input"]');

  setTimeout(() => {
    showJoinKidsAcademyModal({});
  }, 4000);

  const submitSubscriptionForm = () => {
    const tgcSubscriptionPopupFormParsley = $tgcSubscriptionPopupForm.parsley();

    if (!tgcSubscriptionPopupFormParsley.isValid()) {
      tgcSubscriptionPopupFormParsley.validate();
      return;
    }

    toggleButtonLoading($tgcSubscriptionPopupSubmit, true);
    const subscribeEmail = $tgcSubscriptionPopupInput.val().trim();

    $.ajax({
      url: ENDPOINT.USER_SUBSCRIBE,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify({
        list_name: MAILING_LISTS.TGC_LIST,
        email_address: subscribeEmail,
        REF_LANDIN: window.location.href
      })
    })
      .then(() => {
        storage.set(LOCAL_STORAGE_KEYS.IS_BLOG_SUBSCRIBED, 'true');
        toggleButtonLoading($tgcSubscriptionPopupSubmit, false);
        $tgcSubscriptionPopupSubscribe.hide();
        $tgcSubscriptionPopupSuccess.show();
      }, () => {
        toggleButtonLoading($tgcSubscriptionPopupSubmit, false);
        $tgcSubscriptionPopup.modal('hide');

        // TODO add support for spanish lang
        toastr.warning('Something went wrong. Please contact us.');
      });
  };

  $(document)
    .on('click', tgcSubscriptionPopupSubmit, (e) => {
      e.preventDefault();
      submitSubscriptionForm();
    })
    .on('click', showPopupBtn, () => {
      $tgcSubscriptionPopup.modal('show');
    });
});
